<template>
  <div>
    <!-- dialog -->
    <van-popup v-model="bankSelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="banks"
        @confirm="onConfirm"
        @cancel="bankSelect = false"
      />
    </van-popup>
    <van-dialog
      :before-close="delBankBefore"
      v-model="delBankModal"
      title="删除"
      show-cancel-button
    >
      <p style="text-align: center; color: red; padding: 15px">确认删除</p>
      <p style="text-align: center; padding: 15px; padding-top: 0px">
        {{ currentData.bank_no }} - {{ currentData.bank_name }}
      </p>
    </van-dialog>
    <van-dialog
      :before-close="editBankBefore"
      v-model="editBankModal"
      title="编辑"
      show-cancel-button
    >
      <van-form refs>
        <van-field
          v-model="currentData.bank_no"
          name="银行卡号"
          label="银行卡号"
          placeholder="银行卡号"
          :rules="[{ required: true, message: '请填写银行卡号' }]"
        />
        <label
          for=""
          style="
            display: flex;
            padding: 10px 16px;
            color: #646566;
            text-align: left;
            word-wrap: break-word;
            font-size: 14px;
            display: flex;
            align-items: center;
          "
        >
          <span style="width: 6.2em"> 开户银行 </span>
          <multiselect
            style="border: none !important"
            v-model="currentData.bank_name"
            placeholder="选择开户银行"
            :options="banks"
            tagPlaceholder=""
            selectLabel=""
            deselectLabel=""
          >
            <span slot="noResult"> 查无银行 </span>
          </multiselect>
        </label>
        <van-field
          v-model="currentData.bank_branch"
          name="支行名称"
          label="支行名称"
          placeholder="支行名称"
          :rules="[{ required: true, message: '请填写支行名称' }]"
        />
        <van-field
          v-model="currentData.bank_owner"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="addBankBefore"
      v-model="addBankModal"
      title="新增提现账户"
      show-cancel-button
    >
      <van-form ref="addBankCForm">
        <van-field
          v-model="addBankData.bank_no"
          name="银行卡号"
          label="银行卡号"
          placeholder="银行卡号"
          required
          :rules="[{ required: true, message: '请填写银行卡号' }]"
        />
        <multiselect
          v-model="addBankData.bank_name"
          placeholder="选择开户银行"
          :options="banks"
          tagPlaceholder=""
          selectLabel=""
          deselectLabel=""
        >
          <span slot="noResult"> 查无银行 </span>
        </multiselect>
        <van-field
          v-model="addBankData.bank_branch"
          name="支行名称"
          label="支行名称"
          placeholder="支行名称"
          required
          :rules="[{ required: true, message: '请填写支行名称' }]"
        />
        <van-field
          v-model="addBankData.bank_owner"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          required
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          required
          v-model="addBankData.oneCode"
          name="谷歌验证码"
          label="谷歌验证码"
          placeholder="谷歌验证码"
          v-if="$store.state.selfData.enable_2fa == 1"
        />

        <!-- <van-field name="uploader" label="文件上传">
          <template #input>
            <van-uploader v-model="files" :max-count="3" />
          </template>
        </van-field> -->
      </van-form>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar @click-left="onClickLeft" title="提现账户" left-arrow>
        <template #left>
          <van-icon name="arrow-left" />
        </template>
        <template #right>
          <div
            :style="{ color: $returnColor().color3 }"
            class="addAcc"
            @click="
              addBankModal = true;
              addBankData = { type: 1 };
            "
          >
            <van-icon class="plus" name="plus" />新增
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div
      class="wrap"
      :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }"
    >
      <div class="tableWrap" v-for="list in bankList" :key="list.id">
        <p class="tableContent">
          <span class="tableTitle"> 银行卡号 </span>
          <span class="tablesContent">
            {{ list.bank_no }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 开户银行 </span>
          <span class="tablesContent">
            {{ list.bank_name }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 支行名称 </span>
          <span class="tablesContent">
            {{ list.bank_branch }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 姓名 </span>
          <span class="tablesContent">
            {{ list.bank_owner }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 异常 </span>
          <span class="tablesContent">
            {{ list.aberrant == 0 ? "正常" : "异常" }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 启用状态 </span>
          <span>
            <!-- <van-switch
              size="24"
              :value="list.enable == '1'"
              active-color="#3FBBFE"
              inactive-color="#cccccc"
              @input="changeEnable(list)"
            /> -->
            <van-switch
              v-if="list.enable != 2"
              size="24"
              active-color="#3FBBFE"
              inactive-color="#cccccc"
              :value="list.enable == 1"
              @input="changeEnable(list)"
            />
            <span v-else style="color: red">待审核</span>
          </span>
        </p>
        <p class="tableContent" style="width: 100%">
          <span class="tableTitle"> 维护时段 </span>
          <span class="tablesContent" v-if="list.bank.start && list.bank.end">
            {{ list.bank.start }} ~ {{ list.bank.end }}
          </span>
          <span class="tablesContent" v-else> 无 </span>
        </p>
        <div class="editBtn">
          <!-- <p @click="editBank(list)">编辑银行卡</p> -->
          <p style="color: red" @click="delBank(list)">删除银行卡</p>
        </div>
        <!-- <van-button
          @click="editBank(list)"
          block
          size="small"
          plain
          hairline
          type="info"
          >编辑银行卡</van-button
        > -->
      </div>
      <!-- <van-pagination v-model="currentPage" :page-count="paginate.total_page" /> -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "vant";
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  data() {
    return {
      bankSelect: false,
      show: true,
      newPassword: "",
      password: "",
      paginate: {},
      bankList: [],
      addBankModal: false,
      editBankModal: false,
      addBankData: { type: 1 },
      currentPage: 1,
      currentData: {},
      banks: [],
      files: [],
      delBankModal: false,
      next_page: true,
      wait: false,
      current_page: 1,
    };
  },
  watch: {
    currentPage() {
      this.getBank();
    },
  },
  methods: {
    delBank(list) {
      this.currentData = JSON.parse(JSON.stringify(list));
      this.delBankModal = true;
    },
    delBankBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .delete(`/mobile/bankCard/${this.currentData.id}`)
          .then((res) => {
            if (res.data.code == 0) {
              this.getBank();
              this.delBankModal = false;
              Toast.success("成功");
            }
          });
      } else {
        done();
      }
      done();
    },
    onConfirm(value) {
      this.addBankData.bank_name = value;
      this.bankSelect = false;
    },
    editBank(list) {
      this.currentData = JSON.parse(JSON.stringify(list));
      this.editBankModal = true;
    },
    changeEnable(list) {
      let currentData = JSON.parse(JSON.stringify(list));
      this.$dialog
        .confirm({
          title: "提醒",
          message: `是否${list.enable == "1" ? "关闭" : "开启"}账户？`,
        })
        .then(() => {
          currentData.enable = list.enable == "1" ? 0 : 1;
          this.$http
            .put(`/mobile/bankCard/${list.id}`, currentData)
            .then((res) => {
              if (res.data.code == 0) {
                // this.getBank();
                list.enable == "1" ? (list.enable = 0) : (list.enable = 1);
              }
            });
        });
    },
    getBank() {
      this.$http
        .get(`/mobile/bankCard?type=1&page=${this.currentPage}&paginate=1`)
        .then((res) => {
          if (res.data.code == 0) {
            this.wait = false;
            this.next_page = res.data.next_page;
            let listData = JSON.parse(JSON.stringify(this.bankList));
            if (this.currentPage == 1) {
              listData = [];
            }
            res.data.data.forEach((data) => {
              let have = false;
              this.bankList.forEach((e) => {
                if (e.id == data.id) {
                  have = true;
                }
              });
              if (!have) {
                listData.push(data);
              }
            });
            this.bankList = listData;
          }
        });
    },
    async addBankBefore(method, done) {
      if (method == "confirm") {
        if (!this.addBankData.bank_no) {
          Toast.fail("请输入银行卡号");
          done(false);
          return;
        }
        if (!this.addBankData.bank_branch) {
          Toast.fail("请输入支行名称");
          done(false);
          return;
        }
        if (!this.addBankData.bank_name) {
          Toast.fail("请输入开户银行");
          done(false);
          return;
        }
        if (!this.addBankData.bank_owner) {
          Toast.fail("请输入姓名");
          done(false);
          return;
        }
        var formData = new FormData();
        this.files.forEach((e) => {
          formData.append("files[]", e.file);
        });
        Object.keys(this.addBankData).forEach((e) => {
          formData.append(e, this.addBankData[e]);
        });
        this.$http.post(`/mobile/bankCard`, formData).then((res) => {
          if (res.data.code == 0) {
            this.addBankModal = false;
            this.getBank();
            Toast.success("成功");
          }
        });
        done(false);
      } else {
        done();
        return;
      }
    },
    editBankBefore(method, done) {
      if (method == "confirm") {
        this.editBankModal = true;
        let data = this.currentData;
        delete data.enable;
        Object.keys(data).forEach((e) => {
          if (!data[e] && String(data[e]) != 0) {
            delete data[e];
          }
        });
        this.$http
          .put(`/mobile/bankCard/${this.currentData.id}`, data)
          .then((res) => {
            if (res.data.code == 0) {
              this.getBank();
              this.editBankModal = false;
            }
          });
        done(false);
      } else {
        done();
        return;
      }
    },
    onClickLeft() {
      this.$router.push(this.$cookie.get("prevPage") || "/");
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          if (this.wait) return;
          this.wait = true;
          this.current_page += 1;
          this.getBank();
        }
      }
    },
  },
  created() {
    this.getBank();
    this.$http.get(`/mobile/banks`).then((res) => {
      this.banks = res.data.data;
    });
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
  computed: {
    ...mapState([
      "showOverlay",
      // ...
    ]),
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  // background-image: url("/home-page_bg.png");
  // background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tableWrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 355px;
  padding: 10px 20px;
  padding-bottom: 0px;
  border-radius: 10px;
  margin-bottom: 15px;
  .tableContent {
    width: 100%;
    display: flex;
    font-size: 14px;
    height: 25px;
    align-items: center;
    .tableTitle {
      font-weight: 400;
      white-space: nowrap;
      width: 60px;
      text-align: left;
      margin-right: 10px;
    }
    .tablesContent {
      color: #999999;
      word-break: break-all;
    }
    // &:nth-child(odd) {
    //   padding-right: 10px;
    // }
    // &:nth-child(even) {
    //   padding-left: 10px;
    // }
  }
}

.addAcc {
  display: flex;
  align-items: center;
  // color: #009aaa;
  font-size: 14px;
  .plus {
    color: #009aaa !important;
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
    //
  }
}
.editBtn {
  height: 45px;
  text-align: center;
  color: #009aaa;
  width: 100%;
  display: flex;
  p {
    width: 100%;
    line-height: 45px;
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
